import type { GatsbyBrowser } from 'gatsby';

const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  const { href, search } = window.location;

  if (window.dataLayer) {
    window.dataLayer.push({ originalLocation: href });
  }

  if (search.includes('showEditLink=1')) {
    window.localStorage.setItem('showEditLink', '1');
  }
};

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (location?.hash && location.hash !== '#cookie-preference') {
    const element = document?.querySelector(`${location.hash}`) as HTMLElement;

    if (element) {
      element.style.scrollBehavior = 'smooth';
      element.style.scrollSnapAlign = 'start';
      element.style.scrollSnapStop = 'normal';
      element.style.scrollMarginTop = '70px';
    }
  }
};

export { onInitialClientRender, onRouteUpdate };
